.navbar {
    padding-left: .75rem;
    padding-right: .75rem;

    @include from($desktop) {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    &-brand span {
        @extend #{'.is-size-6'};
    }

    &-burger {
        margin-bottom: auto;
        margin-top: auto;
    }

    &-end {
        @include from($desktop) {
            margin-right: .75rem;
        }
    }

    &-item > .logo {
        margin-right: 1em;
    }

    &-item.button {
        color: $button-color;
        margin: auto;

        &:focus,
        &:hover {
            background-color: $button-background-color;
            color: $button-hover-color;
        }
    }
}
