.box {
    &-blue {
        background-color: $blue;

        strong,
        .text,
        .title {
            color: $white;
        }

        .text a:not(.button) {
            border-bottom-color: $white;
        }
    }

    &-white {
        background-color: $white;

        .title {
            color: $blue;
        }
    }
}
