@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: url("../_fonts/inter-v7-latin-regular.woff") format("woff"),
         url("../_fonts/inter-v7-latin-regular.woff2") format("woff2"),
         url("../_fonts/inter-v7-latin-regular.ttf") format("ttf");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    src: url("../_fonts/inter-v7-latin-700.woff") format("woff"),
         url("../_fonts/inter-v7-latin-700.woff2") format("woff2"),
         url("../_fonts/inter-v7-latin-700.ttf") format("ttf");
}
