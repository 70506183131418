$black:        #000;
$blue:         #459dda;
$blue-light:   #c4e3f5;
$green:        #b2cdb4;
$green-light:  $green;
$orange:       #ecaf3f;
$orange-light: #f6d386;
$yellow:       #fef6ba;
$yellow-light: $yellow;
$white:        #fff;

$box-padding: 2em;
$box-radius: 0em;
$box-shadow: none;

$button-background-color: $blue;
$button-border-width: 0em;
$button-color: $white;
$button-focus-color: $white;
$button-hover-color: $white;

$family-primary: "Inter", sans-serif;

$navbar-background-color: $yellow-light;
$navbar-item-hover-background-color: $yellow;
$navbar-item-hover-color: $black;

$title-weight: 700;

/* ------------------------------------
Bulma import order

Version: 0.9.3
Source: /node_modules/bulma/bulma.sass

1. Utilities
2. Base
3. Elements
4. Form
5. Components
6. Grid
7. Helpers
8. Layout
------------------------------------ */

@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";

@import "../node_modules/bulma/sass/elements/box.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/content.sass";
@import "../node_modules/bulma/sass/elements/image.sass";
@import "../node_modules/bulma/sass/elements/other.sass";
@import "../node_modules/bulma/sass/elements/table.sass";
@import "../node_modules/bulma/sass/elements/title.sass";

@import "../node_modules/bulma/sass/components/navbar.sass";

@import "../node_modules/bulma/sass/grid/columns.sass";

@import "../node_modules/bulma/sass/helpers/flexbox.sass";
@import "../node_modules/bulma/sass/helpers/typography.sass";
@import "../node_modules/bulma/sass/helpers/visibility.sass";

@import "../node_modules/bulma/sass/layout/section.sass";
