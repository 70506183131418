.list {
    li {
        padding-left: calc(1em + 5px);
        position: relative;

        &:before {
            content: "";
            height: 10px;
            left: 0;
            position: absolute;
            top: 0.5em;
            width: 10px;
        }

        &:nth-child(5n+1):before {
            background-color: $green;
        }

        &:nth-child(5n+2):before {
            background-color: $blue;
        }

        &:nth-child(5n+3):before {
            background-color: $orange;
        }

        &:nth-child(5n+4):before {
            background-color: $blue-light;
        }

        &:nth-child(5n+5):before {
            background-color: $orange-light;
        }
    }

    ul {
        margin-left: 1em;

        li:before {
            background-color: $text !important;
        }
    }
}
