.text {
    @extend #{'.content'};

    a:not(.button) {
        border-bottom: 1px solid $text;
        color: currentColor;
    }

    ol.list,
    ul.list {
        list-style: none;
        margin: 0;
    }

    table {
        width: auto;

        td,
        th {
            border: 0;
            padding: 0 2em 0 0;
        }
    }
}

.container > .title + .text,
.container > .text {
    @include from($desktop) {
        max-width: 80%;
    }
}
