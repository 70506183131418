.map {
    background-size: cover;
    background-position: center;
    
    iframe,
    .map-notice {
        height: 300px;
        width: 100%;

        @include from($desktop) {
            height: 600px;
        }
    }

    .map-notice {
        align-content: center;
        background-color: rgba(255, 247, 178, .8);
        display: flex;
        backdrop-filter: blur(10px);
        flex-direction: column;
        justify-content: center;
        
        &__text {
            padding: 0 10px;
            text-align: center;
        }

        .button {
            margin-top: 30px;
        }
    }
}