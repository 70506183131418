.button {
    border-radius: 0;
    font-weight: bold;

    &:before {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z'/%3E%3C/svg%3E");
        content: "";
        height: 2em;
        margin-right: 1em;
        width: 2em;
    }
}
