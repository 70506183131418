.footer {
    background-color: $yellow-light;
    @extend #{'.section'};

    &-link {
        color: currentColor;

        + .footer-link {
            margin-left: 1em;
        }
    }

    .columns {
        @extend #{'.is-align-items-flex-end'};
    }

    .has-text-right {
        @include until($desktop) {
            text-align: left !important;
        }
    }
}
