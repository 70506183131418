@charset "utf-8";

/* ------------------------------------
Custom shared stuff
------------------------------------ */
@import "font-faces";

/* ------------------------------------
Update Bulma's global variables
and take only what you need from Bulma
------------------------------------ */
@import "bulma";

/* ------------------------------------
'Register' your components
------------------------------------ */
@import "components";
